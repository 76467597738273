<template>
    <div class="about">
        <h1>About Focus</h1>
        <div>
            Focus is a website for helping people with productivity. The goal was to make a minimal and distraction-free web application that still looked visually interesting. <br>
            Some of the key features are: 
            <ul>
                <li> Pomodoro timer (a timer that has 25 minutes for work and 5 minutes for break)</li>
                <li> An embedded Youtube playlist linking to classical music </li>
                <li> Settings menu that customizes: timer length(s), what music link is playing, overall appearance</li>
                <li> A sign in feature so the settings are stored in the cloud and can be accessed anywhere</li>
            </ul>
        </div>
    </div>
</template>
